import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Button,
  Modal,
  Table,
  message,
  Form,
  Tooltip,
  Input,
  Select,
} from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  EditOutlined,
  CustomerServiceOutlined,
  DollarOutlined,
  SoundOutlined,
  PhoneOutlined,
  SettingOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./AdminPage.css"; // Import custom CSS file

const { Header, Content, Sider, Footer } = Layout;
const { Option } = Select;

const { SubMenu } = Menu;

const AdminPage = () => {
  // State declarations
  const [users, setUsers] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [pricing, setPricing] = useState([]);
  const [voices, setVoices] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [tutorials, setTutorials] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [selectedPricing, setSelectedPricing] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);
  const [isPromptModalVisible, setIsPromptModalVisible] = useState(false);
  const [isAddPromptModalVisible, setIsAddPromptModalVisible] = useState(false);
  const [isEditPromptModalVisible, setIsEditPromptModalVisible] =
    useState(false);
  const [isCustomerModalVisible, setIsCustomerModalVisible] = useState(false);
  const [isPricingModalVisible, setIsPricingModalVisible] = useState(false);
  const [isAddPricingModalVisible, setIsAddPricingModalVisible] =
    useState(false);
  const [isEditPricingModalVisible, setIsEditPricingModalVisible] =
    useState(false);
  const [isVoiceModalVisible, setIsVoiceModalVisible] = useState(false);
  const [isAddVoiceModalVisible, setIsAddVoiceModalVisible] = useState(false);
  const [isEditVoiceModalVisible, setIsEditVoiceModalVisible] = useState(false);
  const [isFaqModalVisible, setIsFaqModalVisible] = useState(false);
  const [isAddFaqModalVisible, setIsAddFaqModalVisible] = useState(false);
  const [isEditFaqModalVisible, setIsEditFaqModalVisible] = useState(false);
  const [isTutorialModalVisible, setIsTutorialModalVisible] = useState(false);
  const [isAddTutorialModalVisible, setIsAddTutorialModalVisible] =
    useState(false);
  const [isEditTutorialModalVisible, setIsEditTutorialModalVisible] =
    useState(false);

  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
  const [isPhoneNumberModalVisible, setIsPhoneNumberModalVisible] =
    useState(false);
  const [isAddPhoneNumberModalVisible, setIsAddPhoneNumberModalVisible] =
    useState(false);
  const [
    isTransferPhoneNumberModalVisible,
    setIsTransferPhoneNumberModalVisible,
  ] = useState(false);
  const [isDeletePhoneNumberModalVisible, setIsDeletePhoneNumberModalVisible] =
    useState(false);

  const [demoCallConfig, setDemoCallConfig] = useState({});
  const [isDemoCallModalVisible, setIsDemoCallModalVisible] = useState(false);
  const [normalVoices, setNormalVoices] = useState([]);
  const [premiumVoices, setPremiumVoices] = useState([]);
  const [voiceCategory, setVoiceCategory] = useState("normal");

  const [form] = Form.useForm();

  const navigate = useNavigate();
  const path = process.env.REACT_APP_API_PATH;

  useEffect(() => {
    fetchUsers();
    fetchPrompts();
    fetchPricing();
    fetchVoices();
    fetchFaqs();
    fetchTutorials();
    fetchPhoneNumbers();
    fetchDemoCallConfig(); // Fetch demo call config when component mounts
  }, []);

  // Fetch normal voices with German filtering
  const fetchNormalVoices = async () => {
    try {
      const response = await axios.get(`${path}/voices`);
      const voices = response.data.voices || [];

      // Filter German voices
      const germanVoices = voices.filter((voice) => {
        const description = voice.description || "";
        const tags = voice.tags || [];
        const isGermanTag = tags.some((tag) =>
          tag.toLowerCase().includes("german")
        );
        const isGermanDescription = description
          .toLowerCase()
          .includes("german");

        const commonGermanWords = [
          "und",
          "der",
          "die",
          "das",
          "ist",
          "nicht",
          "ein",
          "eine",
          "mit",
          "auf",
          "für",
          "von",
          "zu",
          "haben",
        ];
        const isDescriptionInGerman = commonGermanWords.some((word) =>
          description.toLowerCase().includes(word)
        );

        return isGermanTag || isGermanDescription || isDescriptionInGerman;
      });

      setNormalVoices(germanVoices);
      message.success("German voices fetched successfully!");
    } catch (error) {
      message.error("Failed to fetch normal voices: " + error.message);
    }
  };

  // Fetch premium voices
  const fetchPremiumVoices = async () => {
    try {
      const response = await axios.get(`${path}/admin/voices`);
      setPremiumVoices(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      message.error("Failed to fetch premium voices: " + error.message);
    }
  };

  // Handle voice category change and fetch relevant voices
  useEffect(() => {
    setNormalVoices([]);
    setPremiumVoices([]);
    if (voiceCategory === "normal") {
      fetchNormalVoices();
    } else if (voiceCategory === "premium") {
      fetchPremiumVoices();
    }
  }, [voiceCategory]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${path}/users`);
      setUsers(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      message.error("Failed to fetch users: " + error.message);
    }
  };

  const fetchPrompts = async () => {
    try {
      const response = await axios.get(`${path}/all_scripts`);
      setPrompts(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      message.error("Failed to fetch prompts: " + error.message);
    }
  };

  const fetchPricing = async () => {
    try {
      const response = await axios.get(`${path}/pricing-packages`);
      setPricing(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      message.error("Failed to fetch pricing packages: " + error.message);
    }
  };

  const fetchVoices = async () => {
    try {
      const response = await axios.get(`${path}/admin/voices`);
      setVoices(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      message.error("Failed to fetch voices: " + error.message);
    }
  };
  // fetch customers
  const fetchCustomers = async () => {
    try {
      const response = await axios.get(`${path}/customers`);
      setCustomers(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      message.error("Failed to fetch customers: " + error.message);
    }
  };

  const fetchFaqs = async () => {
    try {
      const response = await axios.get(`${path}/faqs`);
      setFaqs(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      message.error("Failed to fetch FAQs: " + error.message);
    }
  };

  const fetchTutorials = async () => {
    try {
      const response = await axios.get(`${path}/tutorials`);
      setTutorials(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      message.error("Failed to fetch Tutorials: " + error.message);
    }
  };

  const fetchPhoneNumbers = async () => {
    try {
      const response = await axios.get(`${path}/admin/get-numbers`);
      setPhoneNumbers(response.data.local_numbers || []);
      message.success("Phone numbers fetched successfully!");
    } catch (error) {
      message.error("Failed to fetch phone numbers: " + error.message);
    }
  };

  // Fetch the current demo call configuration
  const fetchDemoCallConfig = async () => {
    try {
      const response = await axios.get(`${path}/admin/get_call_config`);
      setDemoCallConfig(response.data);
    } catch (error) {
      message.error(
        "Failed to fetch demo call configuration: " + error.message
      );
    }
  };

  // Handle updating the demo call configuration
  const handleUpdateDemoCallConfig = async (values) => {
    try {
      await axios.post(`${path}/admin/set_call_config`, {
        voice_id: selectedVoice,
        script: values.script,
        greeting: values.greeting,
      });
      setIsDemoCallModalVisible(false);
      fetchDemoCallConfig(); // Refresh configuration after update
      message.success("Demo call configuration updated successfully");
    } catch (error) {
      message.error(
        "Failed to update demo call configuration: " + error.message
      );
    }
  };

  const handleAddPhoneNumber = async (values) => {
    try {
      await axios.post(`${path}/admin/add-number`, {
        numbers: [values.phoneNumber],
      });
      setIsAddPhoneNumberModalVisible(false);
      fetchPhoneNumbers();
      message.success("Phone number added successfully!");
    } catch (error) {
      message.error("Failed to add phone number: " + error.message);
    }
  };

  const handleTransferPhoneNumber = async (values) => {
    try {
      await axios.post(`${path}/admin/transfer-number`, {
        phone_number: selectedPhoneNumber,
        subaccount_id: values.subaccount_id,
      });
      setIsTransferPhoneNumberModalVisible(false);
      fetchPhoneNumbers();
      message.success("Phone number transferred successfully!");
    } catch (error) {
      message.error("Failed to transfer phone number: " + error.message);
    }
  };

  const handleDeletePhoneNumber = async () => {
    try {
      await axios.post(`${path}/admin/delete-number`, {
        phone_number: selectedPhoneNumber,
      });
      setIsDeletePhoneNumberModalVisible(false);
      fetchPhoneNumbers();
      message.success("Phone number deleted successfully!");
    } catch (error) {
      message.error("Failed to delete phone number: " + error.message);
    }
  };

  const handleAddFaq = async (values) => {
    try {
      await axios.post(`${path}/admin/faq`, {
        question: values.question,
        answer: values.answer,
      });
      setIsAddFaqModalVisible(false);
      fetchFaqs();
      message.success("FAQ added successfully");
    } catch (error) {
      message.error("Failed to add FAQ: " + error.message);
    }
  };

  const handleEditFaq = async (values) => {
    try {
      await axios.put(`${path}/admin/faq/${selectedFaq._id}`, {
        question: values.question,
        answer: values.answer,
      });
      setIsEditFaqModalVisible(false);
      fetchFaqs();
      message.success("FAQ updated successfully");
    } catch (error) {
      message.error("Failed to update FAQ: " + error.message);
    }
  };

  const handleDeleteFaq = async (faqId) => {
    try {
      await axios.delete(`${path}/admin/faq/${faqId}`);
      fetchFaqs();
      message.success("FAQ deleted successfully");
    } catch (error) {
      message.error("Failed to delete FAQ: " + error.message);
    }
  };

  const handleAddTutorial = async (values) => {
    try {
      await axios.post(`${path}/admin/tutorial`, {
        title: values.title,
        content: values.content,
      });
      setIsAddTutorialModalVisible(false);
      fetchTutorials();
      message.success("Tutorial added successfully");
    } catch (error) {
      message.error("Failed to add Tutorial: " + error.message);
    }
  };

  const handleEditTutorial = async (values) => {
    try {
      await axios.put(`${path}/admin/tutorial/${selectedTutorial._id}`, {
        title: values.title,
        content: values.content,
      });
      setIsEditTutorialModalVisible(false);
      fetchTutorials();
      message.success("Tutorial updated successfully");
    } catch (error) {
      message.error("Failed to update Tutorial: " + error.message);
    }
  };

  const handleDeleteTutorial = async (tutorialId) => {
    try {
      await axios.delete(`${path}/admin/tutorial/${tutorialId}`);
      fetchTutorials();
      message.success("Tutorial deleted successfully");
    } catch (error) {
      message.error("Failed to delete Tutorial: " + error.message);
    }
  };

  const handleDeleteUser = async (username) => {
    try {
      // Send DELETE request to delete the user based on the username
      const response = await axios.delete(`${path}/delete-account`, {
        data: { email: username }, // Assuming 'username' is the email, adjust if needed
      });

      // Check the response for success
      if (response.status === 200) {
        fetchUsers(); // Refresh the users list
        message.success("User deleted successfully");
      } else {
        message.error("Failed to delete user. Please try again.");
      }
    } catch (error) {
      message.error("Failed to delete user: " + error.message);
    }
  };

  const handleAddUser = async (values) => {
    try {
      await axios.post(`${path}/admin/register-user`, {
        firstName: values.firstName,
        lastName: values.lastName,
        username: values.username,
        phoneNumber: values.phoneNumber,
        password: values.password,
        role: values.role,
        callLimit: values.callLimit,
        isSubscribed: values.isSubscribed,
        subaccount_id: values.subaccount_id,
        subaccount_key: values.subaccount_key,
      });
      setIsAddUserModalVisible(false);
      fetchUsers();
      message.success("User added successfully");
    } catch (error) {
      message.error("Failed to add user: " + error.message);
    }
  };

  const handleEditUser = async (values) => {
    try {
      await axios.put(`${path}/user`, {
        username: selectedUser.username,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        password: values.password,
        role: values.role,
        callLimit: values.callLimit,
        isSubscribed: values.isSubscribed,
        subaccount_id: values.subaccount_id,
        subaccount_key: values.subaccount_key,
      });
      setIsEditUserModalVisible(false);
      fetchUsers();
      message.success("User updated successfully");
    } catch (error) {
      message.error("Failed to update user: " + error.message);
    }
  };

  const handleAddPrompt = async (values) => {
    try {
      await axios.post(`${path}/script`, {
        name: values.name,
        content: values.content,
      });
      setIsAddPromptModalVisible(false);
      fetchPrompts();
      message.success("Prompt added successfully");
    } catch (error) {
      message.error("Failed to add prompt: " + error.message);
    }
  };

  const handleEditPrompt = async (values) => {
    try {
      await axios.put(`${path}/script`, {
        script_id: selectedPrompt._id,
        content: values.content,
      });
      setIsEditPromptModalVisible(false);
      fetchPrompts();
      message.success("Prompt updated successfully");
    } catch (error) {
      message.error("Failed to update prompt: " + error.message);
    }
  };

  const handleDeletePrompt = async (promptId) => {
    try {
      await axios.delete(`${path}/script`, { data: { script_id: promptId } });
      fetchPrompts();
      message.success("Prompt deleted successfully");
    } catch (error) {
      message.error("Failed to delete prompt: " + error.message);
    }
  };

  const handleAddPricing = async (values) => {
    try {
      const featuresArray = Array.isArray(values.features)
        ? values.features // If already an array, use it as is
        : values.features.split(",").map((feature) => feature.trim()); // If it's a string, split and trim

      await axios.post(`${path}/admin/set-pricing-details`, {
        package_name: values.package_name,
        product_id: values.product_id,
        price_id: values.price_id,
        price: values.price,
        call_limit: values.call_limit, // Adding call limit
        package_type: values.package_type, // Adding package type
        features: featuresArray, // Now safely use the features array
      });

      setIsAddPricingModalVisible(false);
      fetchPricing();
      message.success("Pricing package added successfully");
    } catch (error) {
      message.error("Failed to add pricing package: " + error.message);
    }
  };

  const handleEditPricing = async (values) => {
    try {
      const featuresArray = Array.isArray(values.features)
        ? values.features // If already an array, use it as is
        : values.features.split(",").map((feature) => feature.trim()); // If it's a string, split and trim

      await axios.put(`${path}/pricing-packages`, {
        _id: selectedPricing._id,
        package_name: values.package_name,
        product_id: values.product_id,
        price_id: values.price_id,
        price: values.price,
        call_limit: values.call_limit, // Adding call limit
        package_type: values.package_type, // Adding package type
        features: featuresArray, // Now safely use the features array
      });

      setIsEditPricingModalVisible(false);
      fetchPricing();
      message.success("Pricing package updated successfully");
    } catch (error) {
      message.error("Failed to update pricing package: " + error.message);
    }
  };

  const handleDeletePricing = async (id) => {
    try {
      await axios.delete(`${path}/pricing-packages`, { data: { _id: id } });
      fetchPricing();
      message.success("Pricing package deleted successfully");
    } catch (error) {
      message.error("Failed to delete pricing package: " + error.message);
    }
  };

  const handleAddVoice = async (values) => {
    try {
      await axios.post(`${path}/admin/voice`, {
        voice_id: values.voice_id,
        name: values.name,
        description: values.description,
      });
      setIsAddVoiceModalVisible(false);
      fetchVoices();
      message.success("Voice added successfully");
    } catch (error) {
      message.error("Failed to add voice: " + error.message);
    }
  };

  const handleEditVoice = async (values) => {
    try {
      await axios.put(`${path}/admin/voice`, {
        voice_id: selectedVoice._id,
        name: values.name,
        description: values.description,
      });
      setIsEditVoiceModalVisible(false);
      fetchVoices();
      message.success("Voice updated successfully");
    } catch (error) {
      message.error("Failed to update voice: " + error.message);
    }
  };

  const handleDeleteVoice = async (voiceId) => {
    try {
      await axios.delete(`${path}/admin/voice/${voiceId}`);
      fetchVoices();
      message.success("Voice deleted successfully");
    } catch (error) {
      message.error("Failed to delete voice: " + error.message);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    navigate("/login");
    message.success("You have been logged out successfully");
  };

  const userColumns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => (text ? "Admin" : "User"),
    },
    {
      title: "Call Limit",
      dataIndex: "call_limit",
      key: "call_limit",
    },
    {
      title: "Subscription Status",
      dataIndex: "is_subscribed",
      key: "is_subscribed",
      render: (text) => (text ? "Subscribed" : "Not Subscribed"),
    },
    {
      title: "Subaccount ID",
      dataIndex: "subaccount_id",
      key: "subaccount_id",
    },
    {
      title: "Subaccount Key",
      dataIndex: "subaccount_key",
      key: "subaccount_key",
    },
    {
      title: "Credits",
      dataIndex: "credits",
      key: "credits",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="action-buttons">
          <Button
            type="primary"
            onClick={() => {
              setSelectedUser(record);
              setIsEditUserModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Button danger onClick={() => handleDeleteUser(record.username)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const promptColumns = [
    {
      title: "Prompt Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Prompt Content",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Button
            onClick={() => {
              setSelectedPrompt(record);
              setIsEditPromptModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Button danger onClick={() => handleDeletePrompt(record._id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  const faqColumns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Button
            onClick={() => {
              setSelectedFaq(record);
              setIsEditFaqModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Button danger onClick={() => handleDeleteFaq(record._id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  const tutorialColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Button
            onClick={() => {
              setSelectedTutorial(record);
              setIsEditTutorialModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Button danger onClick={() => handleDeleteTutorial(record._id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  const customerColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
    },
{
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    
  ];

  const pricingColumns = [
    {
      title: "Package Name",
      dataIndex: "package_name",
      key: "package_name",
    },
    {
      title: "Product ID",
      dataIndex: "product_id",
      key: "product_id",
    },
    {
      title: "Price ID",
      dataIndex: "price_id",
      key: "price_id",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Call Limit",
      dataIndex: "call_limit",
      key: "call_limit", // Adding call limit column
    },
    {
      title: "Package Type",
      dataIndex: "package_type",
      key: "package_type", // Adding package type column
    },
    {
      title: "Features",
      dataIndex: "features",
      key: "features",
      render: (features) => (
        <ul>
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="action-buttons">
          <Button
            type="primary"
            onClick={() => {
              setSelectedPricing(record);
              setIsEditPricingModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Button danger onClick={() => handleDeletePricing(record._id)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const voiceColumns = [
    {
      title: "Voice ID",
      dataIndex: "_id",
      key: "voice_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Button
            onClick={() => {
              setSelectedVoice(record);
              setIsEditVoiceModalVisible(true);
            }}
          >
            Edit
          </Button>

          <Button danger onClick={() => handleDeleteVoice(record._id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  const AddForm = ({ visible, onCreate, onCancel, initialData, formTitle }) => {
    const [form] = Form.useForm();
    return (
      <Modal
        visible={visible}
        title={formTitle}
        okText="Submit"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={initialData || { modifier: "public" }}
        >
          {formTitle.includes("User") ? (
            <>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { required: true, message: "Please input the first name!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  { required: true, message: "Please input the last name!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="username"
                label="Username"
                rules={[
                  { required: true, message: "Please input the username!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[
                  { required: true, message: "Please input the phone number!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: "Please input the password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="role"
                label="Role"
                rules={[{ required: true, message: "Please select the role!" }]}
              >
                <Select>
                  <Select.Option value={true}>Admin</Select.Option>
                  <Select.Option value={false}>User</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="callLimit"
                label="Call Limit"
                rules={[
                  { required: true, message: "Please input the call limit!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="isSubscribed"
                label="Subscription Status"
                rules={[
                  {
                    required: true,
                    message: "Please select subscription status!",
                  },
                ]}
              >
                <Select>
                  <Select.Option value={true}>Subscribed</Select.Option>
                  <Select.Option value={false}>Not Subscribed</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="subaccount_id"
                label="Subaccount ID"
                rules={[
                  {
                    required: true,
                    message: "Please input the subaccount ID!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="subaccount_key"
                label="Subaccount Key"
                rules={[
                  {
                    required: true,
                    message: "Please input the subaccount key!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          ) : formTitle.includes("Prompt") ? (
            <>
              <Form.Item
                name="name"
                label="Prompt Name"
                rules={[
                  { required: true, message: "Please input the prompt name!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="content"
                label="Prompt Content"
                rules={[
                  {
                    required: true,
                    message: "Please input the prompt content!",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </>
          ) : formTitle.includes("Pricing Package") ? (
            <>
              <Form.Item
                name="package_name"
                label="Package Name"
                rules={[
                  { required: true, message: "Please input the package name!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="product_id"
                label="Product ID"
                rules={[
                  { required: true, message: "Please input the product ID!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="price_id"
                label="Price ID"
                rules={[
                  { required: true, message: "Please input the price ID!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="price"
                label="Price"
                rules={[{ required: true, message: "Please input the price!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="call_limit"
                label="Call Limit"
                rules={[
                  { required: true, message: "Please input the call limit!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="package_type"
                label="Package Type"
                rules={[
                  {
                    required: true,
                    message: "Please select the package type!",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="monthly">Monthly</Select.Option>
                  <Select.Option value="quarterly">Quarterly</Select.Option>
                  <Select.Option value="yearly">Yearly</Select.Option>
                  <Select.Option value="addon">Addon</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="features"
                label="Features"
                rules={[
                  { required: true, message: "Please input the features!" },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </>
          ) : formTitle.includes("FAQ") ? (
            <>
              <Form.Item
                name="question"
                label="Question"
                rules={[
                  { required: true, message: "Please input the question!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="answer"
                label="Answer"
                rules={[
                  { required: true, message: "Please input the answer!" },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </>
          ) : formTitle.includes("Tutorial") ? (
            <>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: "Please input the title!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="content"
                label="Content"
                rules={[
                  { required: true, message: "Please input the content!" },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                name="voice_id"
                label="Voice ID"
                rules={[
                  { required: true, message: "Please input the voice ID!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please input the name!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  { required: true, message: "Please input the description!" },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    );
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <SubMenu key="sub1" icon={<UserOutlined />} title="User Management">
            <Menu.Item key="1" onClick={() => setIsUserModalVisible(true)}>
              Show Users
            </Menu.Item>
            <Menu.Item key="2" onClick={() => setIsAddUserModalVisible(true)}>
              Create User
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" icon={<EditOutlined />} title="Prompt Management">
            <Menu.Item key="3" onClick={() => setIsAddPromptModalVisible(true)}>
              Add Prompt
            </Menu.Item>
            <Menu.Item key="4" onClick={() => setIsPromptModalVisible(true)}>
              Show Prompts
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub3"
            icon={<CustomerServiceOutlined />}
            title="Customer Management"
          >
            <Menu.Item
              key="5"
              onClick={() => {
                setIsCustomerModalVisible(true);
                fetchCustomers();
              }}
            >
              Show Customers
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub4"
            icon={<DollarOutlined />}
            title="Pricing Management"
          >
            <Menu.Item
              key="6"
              onClick={() => setIsAddPricingModalVisible(true)}
            >
              Add Pricing Package
            </Menu.Item>
            <Menu.Item key="7" onClick={() => setIsPricingModalVisible(true)}>
              Show Pricing Packages
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub5" icon={<SoundOutlined />} title="Voice Management">
            <Menu.Item key="8" onClick={() => setIsAddVoiceModalVisible(true)}>
              Add Voice
            </Menu.Item>
            <Menu.Item key="9" onClick={() => setIsVoiceModalVisible(true)}>
              Show Voices
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub6" icon={<EditOutlined />} title="FAQ Management">
            <Menu.Item key="10" onClick={() => setIsAddFaqModalVisible(true)}>
              Add FAQ
            </Menu.Item>
            <Menu.Item key="11" onClick={() => setIsFaqModalVisible(true)}>
              Show FAQs
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub7"
            icon={<EditOutlined />}
            title="Tutorial Management"
          >
            <Menu.Item
              key="12"
              onClick={() => setIsAddTutorialModalVisible(true)}
            >
              Add Tutorial
            </Menu.Item>
            <Menu.Item key="13" onClick={() => setIsTutorialModalVisible(true)}>
              Show Tutorials
            </Menu.Item>
          </SubMenu>

          <SubMenu
            key="sub8"
            icon={<PhoneOutlined />}
            title="Phone Number Management"
          >
            <Menu.Item
              key="14"
              onClick={() => setIsPhoneNumberModalVisible(true)}
            >
              Show Phone Numbers
            </Menu.Item>
            <Menu.Item
              key="15"
              onClick={() => setIsAddPhoneNumberModalVisible(true)}
            >
              Add Phone Number
            </Menu.Item>
          </SubMenu>
          {/* Demo Call Configuration Button */}
          <Menu.Item
            key="16"
            icon={<SettingOutlined />}
            onClick={() => setIsDemoCallModalVisible(true)}
          >
            Demo Call Configuration
          </Menu.Item>
          <Menu.Item key="17" icon={<LogoutOutlined />} onClick={handleLogout}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ background: "#fff", padding: 0 }}>
          <div className="header-content">
            <h1>Admin Dashboard</h1>
          </div>
        </Header>
        <Content style={{ margin: "24px 16px 0" }}>
          <div className="content-wrapper">
            <h1>Welcome to the Admin Dashboard</h1>
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>Admin Dashboard ©2024</Footer>
      </Layout>

      <Modal
        title="All Users"
        visible={isUserModalVisible}
        onCancel={() => setIsUserModalVisible(false)}
        footer={null}
      >
        <Table
          dataSource={users}
          columns={userColumns}
          rowKey="username"
          scroll={{ x: "1000px" }} // Added horizontal scroll
        />
      </Modal>

      <AddForm
        visible={isAddUserModalVisible}
        onCreate={handleAddUser}
        onCancel={() => setIsAddUserModalVisible(false)}
        formTitle="Add New User"
      />

      <AddForm
        visible={isEditUserModalVisible}
        onCreate={handleEditUser}
        onCancel={() => setIsEditUserModalVisible(false)}
        initialData={selectedUser}
        formTitle="Edit User"
      />

      <Modal
        title="All Prompts"
        visible={isPromptModalVisible}
        onCancel={() => setIsPromptModalVisible(false)}
        footer={null}
      >
        <Table
          dataSource={prompts}
          columns={promptColumns}
          rowKey="_id"
          rowClassName="prompt-row"
        />
      </Modal>

      <AddForm
        visible={isAddPromptModalVisible}
        onCreate={handleAddPrompt}
        onCancel={() => setIsAddPromptModalVisible(false)}
        formTitle="Add New Prompt"
      />

      <AddForm
        visible={isEditPromptModalVisible}
        onCreate={handleEditPrompt}
        onCancel={() => setIsEditPromptModalVisible(false)}
        initialData={selectedPrompt}
        formTitle="Edit Prompt"
      />

      <Modal
        title="All Customers"
        visible={isCustomerModalVisible}
        onCancel={() => setIsCustomerModalVisible(false)}
        width={1000}
        footer={null}
      >
        <Table
          dataSource={customers}
          columns={customerColumns}
          rowKey="username"
        />
      </Modal>

      <Modal
        title="All Pricing Packages"
        visible={isPricingModalVisible}
        onCancel={() => setIsPricingModalVisible(false)}
        footer={null}
      >
        <Table
          dataSource={pricing}
          columns={pricingColumns}
          rowKey="_id"
          scroll={{ x: 1000 }} // Add horizontal scroll with a width of 1000px or adjust as needed
        />
      </Modal>

      <AddForm
        visible={isAddPricingModalVisible}
        onCreate={handleAddPricing}
        onCancel={() => setIsAddPricingModalVisible(false)}
        formTitle="Add New Pricing Package"
      />

      <AddForm
        visible={isEditPricingModalVisible}
        onCreate={handleEditPricing}
        onCancel={() => setIsEditPricingModalVisible(false)}
        initialData={selectedPricing}
        formTitle="Edit Pricing Package"
      />

      <Modal
        title="All Voices"
        visible={isVoiceModalVisible}
        onCancel={() => setIsVoiceModalVisible(false)}
        footer={null}
      >
        <Table dataSource={voices} columns={voiceColumns} rowKey="_id" />
      </Modal>

      <AddForm
        visible={isAddVoiceModalVisible}
        onCreate={handleAddVoice}
        onCancel={() => setIsAddVoiceModalVisible(false)}
        formTitle="Add New Voice"
      />

      <AddForm
        visible={isEditVoiceModalVisible}
        onCreate={handleEditVoice}
        onCancel={() => setIsEditVoiceModalVisible(false)}
        initialData={selectedVoice}
        formTitle="Edit Voice"
      />

      <Modal
        title="All FAQs"
        visible={isFaqModalVisible}
        onCancel={() => setIsFaqModalVisible(false)}
        footer={null}
      >
        <Table dataSource={faqs} columns={faqColumns} rowKey="_id" />
      </Modal>

      <Modal
        title="All Tutorials"
        visible={isTutorialModalVisible}
        onCancel={() => setIsTutorialModalVisible(false)}
        footer={null}
      >
        <Table dataSource={tutorials} columns={tutorialColumns} rowKey="_id" />
      </Modal>

      <AddForm
        visible={isAddFaqModalVisible}
        onCreate={handleAddFaq}
        onCancel={() => setIsAddFaqModalVisible(false)}
        formTitle="Add New FAQ"
      />

      <AddForm
        visible={isEditFaqModalVisible}
        onCreate={handleEditFaq}
        onCancel={() => setIsEditFaqModalVisible(false)}
        initialData={selectedFaq}
        formTitle="Edit FAQ"
      />

      <AddForm
        visible={isAddTutorialModalVisible}
        onCreate={handleAddTutorial}
        onCancel={() => setIsAddTutorialModalVisible(false)}
        formTitle="Add New Tutorial"
      />

      <AddForm
        visible={isEditTutorialModalVisible}
        onCreate={handleEditTutorial}
        onCancel={() => setIsEditTutorialModalVisible(false)}
        initialData={selectedTutorial}
        formTitle="Edit Tutorial"
      />

      <Modal
        title="All Phone Numbers"
        visible={isPhoneNumberModalVisible}
        onCancel={() => setIsPhoneNumberModalVisible(false)}
        footer={null}
      >
        <Table
          dataSource={phoneNumbers}
          columns={[
            {
              title: "Phone Number",
              dataIndex: "phone_number",
              key: "phone_number",
            },
            {
              title: "Assigned To",
              dataIndex: "assigned_to",
              key: "assigned_to",
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
            },
            {
              title: "Action",
              key: "action",
              render: (_, record) => (
                <div className="action-buttons">
                  <Button
                    type="primary"
                    onClick={() => {
                      setSelectedPhoneNumber(record.phone_number);
                      setIsTransferPhoneNumberModalVisible(true);
                    }}
                  >
                    Transfer
                  </Button>
                  <Button
                    danger
                    onClick={() => {
                      setSelectedPhoneNumber(record.phone_number);
                      setIsDeletePhoneNumberModalVisible(true);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              ),
            },
          ]}
          rowKey="phone_number"
        />
      </Modal>

      <Modal
        title="Add Phone Number"
        visible={isAddPhoneNumberModalVisible}
        onCancel={() => setIsAddPhoneNumberModalVisible(false)}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              handleAddPhoneNumber(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              { required: true, message: "Please input a phone number!" },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Transfer Phone Number"
        visible={isTransferPhoneNumberModalVisible}
        onCancel={() => setIsTransferPhoneNumberModalVisible(false)}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              handleTransferPhoneNumber(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="subaccount_id"
            label="Subaccount ID"
            rules={[
              { required: true, message: "Please input the subaccount ID!" },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Delete Phone Number"
        visible={isDeletePhoneNumberModalVisible}
        onCancel={() => setIsDeletePhoneNumberModalVisible(false)}
        onOk={handleDeletePhoneNumber}
      >
        <p>Are you sure you want to delete this phone number?</p>
      </Modal>

      {/* Demo Call Configuration Modal */}
      <Modal
        title="Demo Call Configuration"
        visible={isDemoCallModalVisible}
        onCancel={() => setIsDemoCallModalVisible(false)}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              handleUpdateDemoCallConfig(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            script: demoCallConfig.script,
            greeting: demoCallConfig.greeting,
          }}
        >
          <Form.Item
            name="script"
            label="Script"
            rules={[{ required: true, message: "Please input the script!" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="greeting"
            label="Greeting"
            rules={[{ required: true, message: "Please input the greeting!" }]}
          >
            <Input />
          </Form.Item>
          {/* Voice Category Selection */}
          <Form.Item
            label={
              <span>
                Select Category{" "}
                <Tooltip title="Select the category of voices">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            required
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Category"
              value={voiceCategory}
              onChange={(value) => setVoiceCategory(value)}
            >
              <Option value="normal">Normal Voices</Option>
              <Option value="premium">Premium Voices</Option>
            </Select>
          </Form.Item>
          {/* Voice Selection */}
          <Form.Item
            label={
              <span>
                Select Voice{" "}
                <Tooltip title="Select a voice for the demo call">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            required
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Voice"
              value={selectedVoice}
              onChange={(value) => setSelectedVoice(value)}
            >
              {(voiceCategory === "normal" ? normalVoices : premiumVoices).map(
                (voice) => (
                  <Option
                    key={voice._id || voice.id}
                    value={voice._id || voice.id}
                  >
                    {voice.name} - {voice.description}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default AdminPage;
